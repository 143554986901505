import React, { useContext } from 'react';
import { StoreContext } from './Store.js';

const FrontLink = (props) => {
  const { state } = useContext(StoreContext);
  const { openUrl } = state.frontContext;

  return (
    <a onClick={(e) => {e.preventDefault(); openUrl(props.href);}} href={props.href}>
      {props.label}
    </a>
  );
};

export default FrontLink;