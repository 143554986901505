import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useStoreState, useStoreDispatch } from './Store.js';
import FrontLink from './FrontLink.js';
import Date from './Date.js';
import HealthScore from './HealthScore.js';
import Revenue from './Revenue.js';
import Boolean from './Boolean.js';
import { DateTime } from 'luxon';

const Info = ({ conversationType, email }) => {
  const dispatch = useStoreDispatch();
  const { secret, frontContext, displayPreferences } = useStoreState();
  const { createDraft } = frontContext;

  const [isLoading, setLoadingState] = useState(true);
  const [isInEditMode, setEditMode] = useState(false);
  const [isAddingField, setIsAddingField] = useState(false);
  const [info, setInfo] = useState({});

  const isEmail = str => str.split('@').length > 1 && str.split('.').length > 1;

  const toggleEditMode = () => setEditMode(!isInEditMode);
  const storeDisplayPreferences = (newPreferences) => dispatch({type: 'display_preferences_updated', value: newPreferences});

  const pickFieldToDisplay = () => isAddingField
    ? null
    : setIsAddingField(true);

  const toggleField = (id) => {
    const newPreferences = displayPreferences.map(p => p.id === id ? Object.assign(p, {display: !p.display}) : p);
    storeDisplayPreferences(newPreferences);
    setIsAddingField(false);
  };

  useEffect(() => {
    if (!email || !isEmail(email) || info.user?.email === email)
      return;
  
    const uri = `/api/fetch/email/${email}?auth_secret=${secret}`;
    const emptyInfo = {user: {email: email}};

    setLoadingState(true);

    fetch(`${uri}`, {
      method: 'GET',
      credentials: 'same-origin',
    })
    .then(r => {
      if (r.status === 400)
        return ({badRequest: true});

      return r.json();
    })
    .then(infoData => setInfo(Object.assign(emptyInfo, infoData)))
    .catch(() => setInfo(emptyInfo))
    .finally(() => setLoadingState(false));
  }, [info, email, secret]);

  if (conversationType !== 'singleConversation')
    return <div className="notice">This plugin only works on single conversations.</div>;

  if (!email || !isEmail(email))
    return <div className="notice">No email address found.</div>;

  if (isLoading)
    return <div className="loading">Loading...</div>;
  
  if (info && info.badRequest)
    return <div className="notice">Bad request. Check that your custom fields all have an existing ID in Gainsight.</div>;

  if (!info || !info.user || !info.account)
    return <div className="not-found">
        <div className="email">{email}</div>
        <div className="copy">No record found</div>
      </div>;

  const {user, account} = info;

  const customFields = displayPreferences.map((p, index) => {
    if (!p.display)
      return undefined;

    const f = account.custom_fields.find(f => f.id === p.id);

    if (!f || typeof f.value === 'undefined')
      return undefined;

    let cell = f.value;
    if (f.type === 'currency')
      cell = <Revenue amount={f.value} />;

    if (f.type === 'boolean')
      cell = <Boolean value={f.value} />;

    if (f.type === 'date')
      cell = <Date value={f.value} />;

    if (f.type === 'score')
      cell = <HealthScore score={f.value} />;

    return <Draggable key={f.id} draggableId={f.id} index={index} isDragDisabled={!isInEditMode}>
      {(provided, snapshot) => (
        <li
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={isInEditMode ? 'editing' : ''} key={f.id}>
          <div className="attribute">
            <div className="attribute-label">{f.label}</div>
            <div className="attribute-value">{cell}</div>
            <div className="attribute-remove" onClick={() => toggleField(f.id)}></div>
          </div>
        </li>
      )}
    </Draggable>;
  }).filter(f => typeof f !== 'undefined');

  const onDragEnd = (result) => {
    if (result.source && result.destination)
      return dispatch({type: 'display_preferences_reordered', value: {source: result.source.index, destination: result.destination.index}});
  };

  return (
    <>
      <div>
        <div className="person">
          <div className="person-company">
            <FrontLink href={account.externalUrl} label={account.name} />
          </div>
          <div className="person-name">{user.name}</div>
          { user.title ? <div className="person-title"><span>{user.title}</span></div> : undefined }
          {/* { user.role ? <div className="person-role"><span>{user.role}</span></div> : undefined } */}
          <div className="person-email"><span className="email-link" onClick={() => createDraft({to: [user.email]})}>{user.email}</span></div>
        </div>

        <div className="account-details">
          <span>Account Details</span>
          <div className={isInEditMode ? 'edit-mode active' : 'edit-mode'} onClick={toggleEditMode}></div>
        </div>
        <div className="attributes">
          {account.owner ? (
            <ul className="attributes-table">
              <li>
                <div className="attribute">
                  <div className="attribute-label">{account.owner.label}</div>
                  <div className="attribute-value">{account.owner.name}</div>
                </div>
              </li>
            </ul>
          ) : (
            <ul className="attributes-table">
              <li>
                <div className="attribute">
                  <div className="attribute-label">Owner</div>
                  <div className="attribute-value">N/A</div>
                </div>
              </li>
            </ul>
          )}
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <ul
                    className="attributes-table"
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {customFields}
                    {isInEditMode && displayPreferences.find(p => p.display === false) ?
                    <li className="editing add-field" onClick={() => pickFieldToDisplay()}>
                      <div className="attribute">
                        {isAddingField ? <ul>{displayPreferences.map(p => p.display ? undefined : <li key={p.id} onClick={() => toggleField(p.id)}>{account.custom_fields.find(f => f.id === p.id).label}</li>)}</ul> : '+ Add a custom field'}
                      </div>
                    </li>
                    : ''}
                    {provided.placeholder}
                  </ul>
                )}
              </Droppable>
            </DragDropContext>
        </div>

        <div className="open-ctas">
          <span>Open CTAs</span>
        </div>
        <div>
          {account.ctas ? (
            <ul className="open-ctas-list">
              {account.ctas.sort((a, b) => DateTime.fromISO(a.date) > DateTime.fromISO(b.date) ? 1 : -1).map(c => 
                <li className="open-ctas-item" key={c.id}>
                  <div className="title"><FrontLink href={c.externalUrl} label={c.name} /></div>
                  <div className="attribute">Owner: {c.owner}</div>
                  <div className="attribute">Type: {c.type} ({c.reason})</div>
                  <div className="attribute">Due: {DateTime.fromISO(c.date).toFormat('D')} ({c.priority} priority)</div>
                </li>)}
            </ul>
          ) : (
            <span className="no-cta-notice">No CTA open for this account.</span>
          )}
        </div>
      </div>
    </>
  );
};

export default Info;