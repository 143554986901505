import React from 'react';
import StoreProvider from './Store.js';
import Application from './Application.js';
import './App.css';

if (!(new URL(document.location.href)).searchParams.get('auth_secret') && !document.location.href.includes('/docs'))
  window.location.href = '/docs';

const App = () => <StoreProvider>
  <Application />
</StoreProvider>;

export default App;