import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useStoreState, useStoreDispatch } from './Store.js';

const FieldsTable = ({ fields, setNewFields }) => {
  const [newField, setNewField] = useState({type: 'string', isNew: true});

  const handleChange = ({ target }) => {
    if (target.name === 'field-label')
      setNewField({...newField, label: target.value});

    if (target.name === 'field-id')
      setNewField({...newField, id: target.value});

    if (target.name === 'field-type')
      setNewField({...newField, type: target.value});
  };

  const addField = () => {
    if (!newField.label || !newField.id || !newField.type || fields.find(f => f.id === newField.id))
      return;

    setNewFields([...fields, newField]);
    setNewField({type: 'string', isNew: true});
  };

  const removeField = (id) => setNewFields(fields.filter(f => f.id !== id));

  return <table className="fields-table">
    <thead>
      <tr>
        <td>Label</td>
        <td>ID</td>
        <td>Type</td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      {fields.map(f =>
      <tr key={f.id} className={f.isNew ? 'new-field' : null}>
        <td>{f.label}</td>
        <td>{f.id}</td>
        <td>{f.type}</td>
        <td onClick={() => removeField(f.id)}><span className="cross-button">+</span></td>
      </tr>
      )}
      <tr>
        <td><input type="text" name="field-label" onChange={handleChange}/></td>
        <td><input type="text" name="field-id" onChange={handleChange}/></td>
        <td>
          <select name="field-type" onChange={handleChange}>
            <option value="string">string</option>
            <option value="date">date</option>
            <option value="score">score</option>
            <option value="currency">currency</option>
            <option value="boolean">boolean</option>
          </select>
        </td>
        <td onClick={addField}><span className="plus-button">+</span></td>
      </tr>
    </tbody>
  </table>;
};

const Settings = () => {
  const dispatch = useStoreDispatch();
  const { secret, settings } = useStoreState();
  const [newFields, setNewFields] = useState(JSON.parse(settings.custom_fields));

  let history = useHistory();
  const [errorStatus, setErrorStatus] = useState(false);

  const { register, handleSubmit } = useForm();
  const onSubmit = data => {
    data.custom_fields = JSON.stringify(newFields.map(f => {delete f.isNew; return f;}));
    console.log(data);

    fetch(`/settings?auth_secret=${secret}`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
    .then(() => {
      dispatch({type: 'settings_updated', value: data});
      history.push('/info');
    })
    .catch((err) => {
      setErrorStatus(true);
      console.log(err);
    });
  };

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>
        <div className="label">Score threshold <span className="tooltip" title="Conversations coming from accounts with a score lower or equal will be tagged">[?]</span></div>
        <div className="field"><input type="number" defaultValue={settings.score_threshold} min="0" max="100" name="score_threshold" ref={register} /></div>
      </label>
      <label>
        <div className="label">Tag applied <span className="tooltip" title="This shared tag will be applied to conversations under the threshold">[?]</span></div>
        <div className="field"><input type="text" defaultValue={settings.score_tag} name="score_tag" ref={register} /></div>
      </label>
      <label>
        <div className="label">Custom fields</div>
        <FieldsTable fields={newFields} setNewFields={setNewFields} />
      </label>
      <input type="submit" value="Save" />
      <span className="close-button">
        <Link to="/info">Close</Link>
      </span>
      {errorStatus ? <div className="error-message">Invalid credentials.</div> : ''}
    </form>
  </>;
};

export default Settings;