import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { useStoreState } from './Store.js';
import Credentials from './Credentials.js';
import Settings from './Settings.js';
import Info from './Info.js';
import ContactsDropdown from './ContactsDropdown.js';

const Application = () => {
  const { shouldRequireConfig, requirements, theme, frontContext } = useStoreState();
  const { type, conversation } = frontContext;
  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [currentContact, setCurrentContact] = useState(null);
  const toggleSettingsMenu = () => setShowSettingsMenu(!showSettingsMenu);

  useEffect(() => {
    if (!frontContext.listMessages)
      return undefined;

    frontContext.listMessages().then(r => {
      const allMessagesContacts = r.results
        .map(m => [...(m.to.map(t => t.handle)), m.from?.handle, ...(m.cc?.map(c => c.handle) || []), ...(m.bcc?.map(b => b.handle) || [])])
        .flat()
        .filter((value, index, self) => self.indexOf(value) === index);

      setCurrentContact(conversation?.recipient?.handle);
      setContacts(allMessagesContacts);
    });
  }, [frontContext, conversation]);

  return <div className={`app ${theme}`}>
    <Router>
      <div className="credentials-logo">
        <div className="settings-gear" onClick={toggleSettingsMenu}></div>
        { showSettingsMenu ? (
          <ul className="settings-menu" onClick={toggleSettingsMenu}>
            <li><Link to="/settings">Settings</Link></li>
            <li><Link to="/credentials">Edit credentials</Link></li>
          </ul>
        ) : ''}
      </div>
      <Switch>
        <Route exact path="/">
          {/* If a valid config is not required, we can redirect to the main view */}
          <Credentials requirements={requirements} redirect={!shouldRequireConfig} />
        </Route>
        <Route path="/credentials">
          <Credentials requirements={requirements} />
        </Route>
        <Route path="/settings">
          <Settings />
        </Route>
        <Route path="/info">
          <ContactsDropdown contacts={contacts} currentContact={currentContact} setContact={setCurrentContact} />
          <Info conversationType={type} email={currentContact} />
        </Route>
      </Switch>
    </Router>
  </div>;
};

export default Application;