import React from 'react';

const HealthScore = (props) => {
  const color = isNaN(props.score)
    ? props.score
    : props.score > 70
      ? 'green'
      : props.score > 50
        ? 'yellow'
        : 'red';

  return <span className={`healthscore ${color}`}>
    {props.score}
  </span>
};

export default HealthScore;