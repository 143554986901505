import React, { createContext, useContext, useReducer, useEffect, useMemo } from 'react';
import { contextUpdates } from '@frontapp/plugin-sdk';

export const StoreContext = createContext(null);

export const useStoreState = () => {
  const { state } = useContext(StoreContext);
  return state;
};

export const useStoreDispatch = () => {
  const { dispatch } = useContext(StoreContext);
  return dispatch;
};

const initDisplayPreferences = (fields) => {
  // The display preferences are set locally
  const storedPreferencesString = localStorage.getItem('displayPreferences');
  const defaultPreferences = fields.map(f => ({id: f.id, display: true}));

  // No locally set preferences: return the defaults
  if (!storedPreferencesString)
    return defaultPreferences;

  // If locally set preferences, make sure all the fields are still available, or add new fields
  const storedPreferences = JSON.parse(storedPreferencesString);
  const newFieldsAvailable = defaultPreferences.filter(dp => !storedPreferences.find(sp => sp.id === dp.id));
  return storedPreferences.filter(sp => defaultPreferences.find(dp => dp.id === sp.id)).concat(newFieldsAvailable);
};

const storeDisplayPreferencesLocally = (dp) => localStorage.setItem('displayPreferences', JSON.stringify(dp));

const stateReducer = (oldState, action) => {
  console.log(action);

  if (action.type === 'boot_data_received') {
    const { value } = action;

    return {
      secret: value.secret,
      // If config isn't valid then require a valid config
      shouldRequireConfig: !value.isValidConfig,
      requirements: value.requirements,
      settings: value.settings,
      theme: value.theme,
      displayPreferences: value.settings.custom_fields ? initDisplayPreferences(JSON.parse(value.settings.custom_fields)) : [],
    };
  }

  if (action.type === 'new_context_received')
    return {...oldState, frontContext: action.value};

  if (action.type === 'settings_updated') {
    const { value } = action;
    // If one or several fields have been removed we must update display preferences
    const newDisplayPreferences = initDisplayPreferences(JSON.parse(value.custom_fields));
    storeDisplayPreferencesLocally(newDisplayPreferences);
    return {...oldState, settings: value, displayPreferences: newDisplayPreferences};
  }

  if (action.type === 'display_preferences_updated') {
    const displayPreferences = action.value;
    storeDisplayPreferencesLocally(displayPreferences);
    return {...oldState, displayPreferences};
  }

  if (action.type === 'display_preferences_reordered') {
    const { value } = action;
    const displayPreferences = Array.from(oldState.displayPreferences)
    const [removed] = displayPreferences.splice(value.source, 1);
    displayPreferences.splice(value.destination, 0, removed);
    storeDisplayPreferencesLocally(displayPreferences);
    return {...oldState, displayPreferences};
  }

  return oldState;
};

export default ({ children }) => {
  const [state, dispatch] = useReducer(stateReducer, null);
  const hasBooted = Boolean(state?.requirements);

  useEffect(() => {
    // The auth_secret is used for authentication of the plugin
    const secret = (new URL(document.location.href)).searchParams.get('auth_secret') || localStorage.getItem('auth_secret');
    console.log(`Secret is ${secret}`);

    if (secret !== 'null')
      localStorage.setItem('auth_secret', secret);

    // Fetch boot data
    // FIXME: abort fetch on component unmount
    fetch(`/boot?auth_secret=${secret}`, {
      method: 'GET',
      credentials: 'same-origin',
    })
    .then((r) => r.json())
    .then((data) => dispatch({type: 'boot_data_received', value: {secret, ...data}}))
    .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!hasBooted)
      return undefined;

    // Mostly boilerplate code to get Front's context
    // Subscribe to updates of Front's context
    const subscription = contextUpdates.subscribe(newContext => dispatch({type: 'new_context_received', value: newContext}));
    return () => subscription.unsubscribe();
  }, [hasBooted]);

  const storeContextValue = useMemo(() => ({
    state,
    dispatch
  }), [state, dispatch]);

  if (!hasBooted || !state?.frontContext)
    return null;

  return <StoreContext.Provider value={storeContextValue}>
    {children}
  </StoreContext.Provider>;
};